<script lang="ts">
	import Icon from '$components/Icon.svelte';

	export let isGuidelinesShowing: boolean = false;

	let competitors = [
		{ name: 'NSFW ChatGPT', slug: 'chatgpt' },
		{ name: 'Uncensored Character.ai', slug: 'character-ai' },
		{ name: 'Uncensored Nomi AI', slug: 'nomi-vs-nastia', full_slug: true },
		{ name: 'Uncensored Replika', slug: 'replika' },
		{ name: 'Uncensored Paradot', slug: 'paradot' },
		{ name: 'Uncensored Claude', slug: 'claude' },
		{ name: 'Uncensored Gemini', slug: 'gemini' },
		{ name: 'Uncensored Kuki', slug: 'kuki' },
		{ name: 'Uncensored Romantic AI', slug: 'romantic-ai' },
		{ name: 'Uncensored Botify', slug: 'botify' },
		{ name: 'Uncensored Chai', slug: 'chai' },
		{ name: 'Uncensored Anima AI', slug: 'anima-ai' },
		{ name: 'Uncensored Eva AI', slug: 'eva-ai' },
		{ name: 'Uncensored Poe', slug: 'poe' },
		{ name: 'Uncensored Kajiwoto', slug: 'kajiwoto' }
	];

	let animeChats = [
		'yukino-yukinoshita',
		'robin-nico',
		'marin-kitagawa',
		'kannu-kamui',
		'rimuru-tempest'
	];

	const isAlternateLandingShowing = false;
	let landings = [{ url: 'ai-feedee', title: 'AI feedee' }];
</script>

<footer class="footer mt-[88px] bg-tertiary p-4 pt-9">
	<div class="mx-auto flex max-w-7xl flex-col gap-[60px]">
		<div
			class=" font-gigasans text-[2.5rem] font-bold uppercase leading-[3.25rem] text-primary sm:text-[5.4rem] sm:leading-[6.5rem]"
		>
			Say goodbye to loneliness.
		</div>

		<div class="flex flex-col gap-[60px] sm:gap-2">
			<div
				class="flex flex-col gap-[60px] sm:flex-row-reverse sm:items-center sm:justify-between sm:gap-2"
			>
				<div>
					<a href="/" class="inline-block">
						<img
							class="max-h-[53px] sm:max-h-[58px]"
							loading="lazy"
							src="/logo.png"
							alt="logo nastia"
						/>
					</a>
				</div>

				<!-- Landing sections -->
				<div class="flex flex-col sm:flex-row sm:flex-wrap">
					<a class="footer--link sm:-ml-4" href="/about">About</a>
					<a class="footer--link" href="/install">Install the app</a>
				</div>
			</div>
		</div>

		<div class="flex flex-wrap gap-4">
			{#if isAlternateLandingShowing}
				<div class="hidden flex-col sm:-ml-4 sm:flex">
					<span class="my-2 font-gigasans text-2xl leading-[150%] text-primary drop-shadow"
						>AI for you</span
					>
					{#each landings as l}
						<a class="footer--link -mt-3 min-w-fit !text-sm capitalize" href={l.url}>{l.title}</a>
					{/each}
				</div>
			{/if}
			<div class="hidden flex-col sm:-ml-4 sm:flex">
				<span
					class="my-2 max-w-fit px-4 font-gigasans text-2xl leading-[150%] text-primary drop-shadow"
					>Why choose Nastia?</span
				>
				<a class="footer--link -mt-3 min-w-fit !text-sm" href="/blog/best-ai-sex-chat-app"
					>Overview of the best alternatives
				</a>
				{#each competitors as competitor}
					<a
						class="footer--link -mt-3 min-w-fit !text-sm"
						href="/compare/{competitor.slug}{competitor?.full_slug ? '' : '-alternative'}"
						>{competitor.name} alternative</a
					>
				{/each}
			</div>
			<div class="hidden flex-col sm:-ml-4 sm:flex">
				<span class="my-2 font-gigasans text-2xl leading-[150%] text-primary drop-shadow"
					>Chat with Anime Characters</span
				>
				{#each animeChats as chat}
					<a class="footer--link -mt-3 min-w-fit !text-sm capitalize" href="/chat/{chat}"
						>{chat.split('-').reverse().join(' ')} AI chat</a
					>
				{/each}
			</div>
			<div class="hidden flex-col sm:-ml-4 sm:flex">
				<span
					class="my-2 max-w-fit px-4 font-gigasans text-2xl leading-[150%] text-primary drop-shadow"
				>
					Tools
				</span>
				<a
					class="footer--link -mt-3 min-w-fit !text-sm capitalize"
					href="/tools/uncensored-ai-image-generator">Uncensored AI Image Generator</a
				>
				<a
					class="footer--link -mt-3 min-w-fit !text-sm capitalize"
					href="/tools/fake-text-message-generator"
				>
					Fake Text Message (SMS) Generator
				</a>
			</div>
		</div>

		<!-- Guidelines (1/2)-->
		{#if isGuidelinesShowing}
			<div
				class="hidden w-full flex-wrap-reverse items-end justify-between gap-1 p-4 sm:flex sm:place-self-start"
			>
				<div class="mt-10 text-sm font-extralight sm:mt-auto">
					<span class="text-sm font-light">Nastia Cybernetics</span><br />
					11 res les primevères<br />
					97320 France<br />
					+33 374 474 138<br />
					SIREN - 982046385
				</div>

				<div class="ml-auto mt-10 flex flex-col gap-1">
					<div class="flex items-end">
						<div class="footer--link">
							<img
								src="/guidelines/merchant/visa-white.png"
								loading="lazy"
								class="max-h-[24px] w-[50px]"
								alt="visa"
							/>
						</div>
						<div class="footer--link">
							<img
								src="/guidelines/merchant/visa-secure-blue-on-black.png"
								loading="lazy"
								class="max-h-[36px]"
								alt="visa secure"
							/>
						</div>
					</div>
					<div class="-my-4 flex items-end">
						<div class="footer--link">
							<img
								src="/guidelines/merchant/mastercard.svg"
								loading="lazy"
								alt="mastercard"
								width="50"
							/>
						</div>
						<img
							class="relative bottom-2 left-2 max-h-[48px]"
							loading="lazy"
							src="/guidelines/merchant/mastercard-id-check-vrt-white.svg"
							alt="mastercard id check"
						/>
					</div>
				</div>
			</div>
		{/if}

		<div class="space-between flex flex-col sm:flex-row sm:items-center sm:justify-between">
			<!-- Social Links -->
			<div class="flex flex-row items-end justify-around">
				<a
					href="https://www.reddit.com/r/nastia"
					target="_blank"
					rel="noreferrer"
					class="footer--link"
					aria-label="reddit"
				>
					<div class="block sm:hidden">
						<Icon name="reddit" height="52px" width="52px" focusable />
					</div>
					<div class="hidden sm:block">
						<Icon name="reddit" focusable />
					</div>
				</a>
				<a
					href="https://www.nastia.ai/discord"
					target="_blank"
					rel="noreferrer"
					class="footer--link"
					aria-label="discord"
				>
					<div class="block sm:hidden">
						<Icon name="discord" height="52px" width="52px" focusable />
					</div>
					<div class="hidden sm:block">
						<Icon name="discord" focusable />
					</div>
				</a>
				<a
					href="https://twitter.com/nastia_ai"
					target="_blank"
					rel="noreferrer"
					class="footer--link"
					aria-label="twitter"
				>
					<div class="block sm:hidden">
						<Icon name="twitter" height="52px" width="52px" focusable />
					</div>
					<div class="hidden sm:block">
						<Icon name="twitter" focusable />
					</div>
				</a>
			</div>

			<!-- Utilitary links -->
			<div class="mt-[60px] flex flex-col sm:mt-0 sm:flex-row">
				<a class="footer--link" href="/terms">Terms of Service</a>
				<a class="footer--link" href="/privacy">Privacy Policy</a>
				{#if isAlternateLandingShowing}
					<div class="flex flex-col sm:-ml-4 sm:hidden">
						<span
							class="my-2 max-w-fit bg-black/20 px-4 py-2 font-gigasans text-sm leading-[150%] text-primary"
							>AI for you</span
						>
						{#each landings as l}
							<a class="footer--link -mt-3 w-min min-w-fit capitalize" href={l.url}>
								{l.title}
							</a>
						{/each}
					</div>
				{/if}
				<div class="flex flex-col sm:-ml-4 sm:hidden">
					<span
						class="my-2 max-w-fit bg-black/20 px-4 py-2 font-gigasans text-sm leading-[150%] text-primary"
						>Why choose Nastia?</span
					>
					<a class="footer--link -mt-3 w-min min-w-fit" href="/blog/best-ai-sex-chat-app"
						>Overview of the best alternatives
					</a>
					{#each competitors as competitor}
						<a
							class="footer--link -mt-3 w-min min-w-fit"
							href="/compare/{competitor.slug}{competitor?.full_slug ? '' : '-alternative'}"
							>{competitor.name} alternative</a
						>
					{/each}
				</div>
				<div class="flex flex-col sm:-ml-4 sm:hidden">
					<span
						class="my-2 max-w-fit bg-black/20 px-4 py-2 font-gigasans text-sm leading-[150%] text-primary"
						>Chat with Anime Characters</span
					>
					{#each animeChats as chat}
						<a class="footer--link -mt-3 w-min min-w-fit capitalize" href="/chat/{chat}"
							>{chat.split('-').reverse().join(' ')} AI chat</a
						>
					{/each}
				</div>
				<div class="flex flex-col sm:-ml-4 sm:hidden">
					<span
						class="my-2 max-w-fit bg-black/20 px-4 py-2 font-gigasans text-sm leading-[150%] text-primary"
					>
						Tools
					</span>
					<a
						class="footer--link -mt-3 w-min min-w-fit capitalize"
						href="/tools/uncensored-ai-image-generator">Uncensored AI Image Generator</a
					>
					<a
						class="footer--link -mt-3 w-min min-w-fit capitalize"
						href="/tools/fake-text-message-generator">Fake Text Message (SMS) Generator</a
					>
				</div>
			</div>

			<!-- Guidelines (2/2)-->
			{#if isGuidelinesShowing}
				<div
					class="flex w-full flex-wrap-reverse items-end justify-between gap-1 p-4 sm:hidden sm:place-self-start"
				>
					<div class="mt-10 text-sm font-extralight sm:mt-auto">
						<span class="text-sm font-light">Nastia Cybernetics</span><br />
						11 res les primevères<br />
						97320 France<br />
						+33 374 474 138<br />
						SIREN - 982046385
					</div>

					<div class="ml-auto mt-10 flex flex-col gap-1">
						<div class="flex items-end">
							<div class="footer--link">
								<img
									src="/guidelines/merchant/visa-white.png"
									loading="lazy"
									class="max-h-[24px] w-[50px]"
									alt="visa"
								/>
							</div>
							<div class="footer--link">
								<img
									src="/guidelines/merchant/visa-secure-blue-on-black.png"
									loading="lazy"
									class="max-h-[36px]"
									alt="visa secure"
								/>
							</div>
						</div>
						<div class="-my-4 flex items-end">
							<div class="footer--link">
								<img
									src="/guidelines/merchant/mastercard.svg"
									loading="lazy"
									alt="mastercard"
									width="50"
								/>
							</div>
							<img
								src="/guidelines/merchant/mastercard-id-check-vrt-white.svg"
								loading="lazy"
								class="relative bottom-2 left-2 max-h-[48px]"
								alt="mastercard id check"
							/>
						</div>
					</div>
				</div>
			{/if}

			<div class="px-4 py-2 text-xs font-light leading-[150%] text-white sm:text-lg">
				Copyright &copy; Nastia Cybernetics 2023 - {new Date().getFullYear()}. All rights Reserved
			</div>
		</div>
	</div>
</footer>

<style lang="postcss">
	.footer--link {
		@apply px-4 py-[15px] text-xs font-light leading-[150%] text-white hover:text-white sm:text-lg;
	}
</style>
